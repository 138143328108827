import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";

function About() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      <main className="flex-grow px-5 pt-10 xl:mx-96 xl:px-32 xl:pt-40">
        <h1
          className="pt-16 text-center text-4xl font-bold text-white md:mb-6 md:text-7xl"
          data-aos="zoom-y-out"
        >
          About us
        </h1>
        <p
          className="mb-20 text-center text-base font-normal text-secondary-100 md:mx-32 md:text-base"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Kuri Plugins focuses on providing overpowered, non-automated plugins
          that you can safely use on your main account.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Our Story
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Kuri Plugins is more than just a collection of OSRS plugins; it's a
          community created by gamers, for gamers. Our journey began with a
          simple yet powerful idea: to make the OSRS experience better, more
          relaxed, and more enjoyable. Our goal is to ensure that every moment
          you spend playing OSRS is filled with enjoyment, eliminating the
          tedious aspects of the game or at least reducing the attention they
          require.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Prologue
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Before Kuri Plugins, the OpenOSRS era saw the development of several
          plugins by Kuri, such as one-click-wintertodt, one-click-tempoross,
          one-click-gotr, and gauntlet-helper, among others. When Jagex shut
          down that project, I decided to create Kuri Plugins, a safe space
          where players can enjoy the benefits of a RuneLite client with no
          plugin restrictions.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Where we are now
        </h2>
        <p
          className="mb-3 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Kuri Plugins is now in a closed beta phase, offering a range of
          plugins for testing and feedback:
        </p>
        <ul
          className="mb-20 ml-4 list-disc text-left text-base leading-relaxed text-white md:ml-10 md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          <li>Kuri Click Shop: One-click shopping at any shop in OSRS.</li>
          <li>
            Kuri Click Combine: One-click combining of items such as Herblore
            and Cooking ingredients.
          </li>
          <li>Kuri Click GOTR: One-click Guardians of the Rift minigame.</li>
          <li>Kuri Click Wintertodt: One-click Wintertodt minigame.</li>
          <li>Kuri Click Tempoross: One-click Tempoross minigame.</li>
          <li>
            Gauntlet Helper: An all-in-one tool for the Gauntlet and Corrupted
            Gauntlet.
          </li>
          <li>Zulrah Helper: An all-in-one tool for the Zulrah boss.</li>
          <li>OpenOSRS ported plugins</li>
          <li>... And more!</li>
        </ul>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
        >
          Join Us: Craft the Future of OSRS
        </h2>
        <p
          className="mb-5 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          At Kuri Plugins, we're always on the lookout for passionate
          individuals who share our commitment to creating exceptional products.
          If you're enthusiastic about OSRS and want to be part of a team that's
          redefining the gaming experience, you've come to the right place.
        </p>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          Join Kuri Plugins and become a part of a vibrant community that's
          revolutionizing the OSRS experience. Together, we're building a space
          where every gamer can find a sense of belonging.
        </p>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default About;
