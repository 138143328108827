import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";

export default function Privacy() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow px-5 pt-10 xl:mx-96 xl:px-32 xl:pt-40">
        <h1 className="mb-10 pt-16 text-center text-4xl font-bold text-white">
          Privacy Policy
        </h1>

        <p className="mb-10 text-left text-base leading-relaxed text-white md:text-lg">
          <b>Last Updated:</b> 15th October 2023
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Welcome
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          This Privacy Policy explains how we collect, use, and protect your
          information while you use our website at{" "}
          <a
            className="text-blue-600 underline"
            href="https://kuriplugins.com/"
          >
            https://kuriplugins.com/
          </a>{" "}
          and any of its services, products, and features (collectively referred
          to as the "Website"). Your privacy and data security are of the utmost
          importance to us.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Data Collection
        </h2>
        <p className="mb-5 text-left text-base leading-relaxed text-white md:text-lg">
          <strong className="underline">No Personal Identifiable Data:</strong>{" "}
          We do not collect any personally identifiable information (PII) about
          our users. You can visit our Website and use our services without
          revealing any personal information.
        </p>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          <strong className="underline">No Cookies:</strong> We do not use
          cookies on our Website. Your use of our services will not result in
          the storage or retrieval of any data stored in cookies.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Information We Automatically Collect
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          While we do not collect PII or use cookies, we may collect
          non-personal information such as your IP address, browser type,
          operating system, and the pages you visit on our Website. This
          information is collected to improve the user experience and ensure the
          functionality and security of our services.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Third-Party Links
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          Our Website may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these third-party
          websites. We encourage you to read their privacy policies.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Children's Privacy
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          Our services are not intended for individuals under the age of 13. We
          do not knowingly collect personal information from children under 13.
          If you believe we have collected personal information from a child
          under 13, please contact us, and we will promptly take steps to delete
          such information.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Changes to this Privacy Policy
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          We may update our Privacy Policy from time to time. Any changes to
          this policy will be posted on this page with a revised "Last Updated"
          date. We recommend checking this page regularly for updates.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Contact Us
        </h2>
        <p className="mb-5 text-left text-base leading-relaxed text-white md:text-lg">
          If you have any questions, concerns, or feedback regarding this
          Privacy Policy or our privacy practices, please contact us at:
        </p>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          Email:{" "}
          <a
            className="text-blue-600 underline"
            href="mailto:kuri.plugins@gmail.com"
          >
            kuri.plugins@gmail.com
          </a>
        </p>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}
