import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Markdown from "react-markdown";
import { KuriPlugins } from "../../constants/data";
import Footer from "../home/Footer";
import Header from "../home/Header";

const PluginDetails = () => {
  const { id } = useParams();
  const [plugin, setPlugin] = useState(null);
  const [mdFile, setMdFile] = useState("");

  useEffect(() => {
    const foundPlugin = KuriPlugins.find((p) => p.id === id);

    setPlugin(foundPlugin);

    fetch(process.env.PUBLIC_URL + foundPlugin.readme)
      .then((res) => res.text())
      .then((md) => {
        setMdFile({ md });
      });
  }, [id]);

  if (!plugin) {
    return (
      <div className="flex min-h-screen flex-col overflow-hidden">
        <Header />
        <div className="flex-grow"></div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Header />

      <div className="pt-10 md:pt-40">
        <section className="px-5 py-14 sm:px-6 md:mx-20 xl:mx-40">
          <div className="pb-12 md:pb-20">
            <div className="mb-10">
              <h1
                className={`text-center text-3xl font-medium text-white sm:text-4xl md:text-5xl lg:text-6xl`}
                data-aos="slide-right"
                data-aos-delay="150"
              >
                {plugin.name}
              </h1>
            </div>
            <div className="flex flex-col justify-center ">
              <div className="flex flex-col justify-center lg:flex-row">
                {/* Left Panel */}
                <div
                  className="mx-4 w-auto rounded-lg border-black bg-background-500 p-4 font-semibold text-white shadow-rightBottom  lg:w-1/3"
                  data-aos="slide-up"
                  data-aos-delay="150"
                >
                  <div className="mb-5 flex justify-between">
                    <span className="text-left text-sm italic text-yellow-300">
                      Type: {plugin.category}
                    </span>
                    <span className="text-left text-sm italic text-yellow-300">
                      Plugin Version: {plugin.version}
                    </span>
                  </div>
                  {/* ReadMe File */}
                  <div>
                    <Markdown
                      children={mdFile.md}
                      components={{
                        h2: ({ children, ...rest }) => (
                          <h2
                            className="mb-3 mt-3 border-b-1 border-primary-100 text-lg font-bold text-primary-100"
                            {...rest}
                          >
                            {children}
                          </h2>
                        ),
                        a: ({ children, ...rest }) => (
                          <Link
                            className="text-primary-200 underline"
                            to={rest.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children}
                          </Link>
                        ),
                        p: ({ children, ...rest }) => (
                          <p className="text-sm">{children}</p>
                        ),
                        ul: ({ children, ...rest }) => (
                          <ul className="marker:text-primary-200 [&>li]:list-disc">
                            {children}
                          </ul>
                        ),
                        ol: ({ children, ...rest }) => (
                          <ol className="marker:text-primary-200 [&>li]:list-decimal">
                            {children}
                          </ol>
                        ),
                        li: ({ children, ...rest }) => (
                          <li className="list-de ml-5 text-sm">{children}</li>
                        ),
                        pre: ({ children, ...rest }) => (
                          <pre className="my-1">{children}</pre>
                        ),
                        code: ({ children, ...rest }) => (
                          <code className="mr-1 rounded-md bg-gray-600 px-1 text-sm">
                            {children}
                          </code>
                        ),
                      }}
                    />
                  </div>
                </div>

                {/* Right Panel */}
                <div
                  className="mx-4 w-auto self-start rounded-lg bg-background-500 font-semibold text-white shadow-rightBottom lg:w-80"
                  data-aos="slide-left"
                  data-aos-delay="150"
                >
                  <img
                    className="rounded-lg rounded-tl-none rounded-tr-none lg:rounded-lg"
                    src={plugin.img}
                    alt="Plugin Thumbnail"
                  />
                  {/* Right panel labels and buttons */}

                  {/* <div className="space-y-5 px-5 pt-5">
                    <div className="flex justify-between">
                      <label className="text-right">Label:</label>
                      <span className="ml-2 text-right">0</span>
                    </div>

                    <div className="flex justify-between">
                      <label className="text-right">Label:</label>
                      <span className="ml-2">0</span>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-center gap-y-5 px-5 py-4">
                    <button className="btn-gradient mr-2 h-9 w-full rounded-xl font-semibold text-white transition-colors hover:bg-white"
                      onClick={handleFirstButton}>
                      Button
                    </button>
                    <button className="mr-2 h-9 w-full rounded-xl bg-white font-semibold text-[#0d0b1e] transition-colors hover:bg-[#336bc7] hover:text-white"
                      onClick={handleSecondButton}>
                      Button
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PluginDetails;
