import React from "react";
import Header from "./Header";
import HeroHome from "./HeroHome";
import PopularPluginsList from "./PopularPluginsList";
import GettingStartedHome from "./GettingStartedHome";
import PluginList from "./PluginList";
import Footer from "./Footer";
import { Analytics } from "@vercel/analytics/react";

export default function Home() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Analytics />

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <PopularPluginsList />
        <GettingStartedHome />
        <PluginList />
        <Footer />
      </main>
    </div>
  );
}
