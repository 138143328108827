import React from "react";
import RegisterFailImage from "../../assets/register-fail.png";
import { useNavigate } from "react-router-dom";

export default function Failure(props) {
  const navigate = useNavigate();
  const onClose = () => {
    navigate("/");
  };

  const getMessage = () => {
    switch (props.status) {
      case "400":
        return "You must have a valid registration key to be able to use Kuri Plugins.";
      case "409":
        return "User already exists. Please try registering with a different account.";
      default:
        return "An unexpected error occurred. Please try again later.";
    }
  };

  return (
    <div className="box-background mx-auto w-full p-10 shadow-lg max-[485px]:absolute max-[485px]:h-full max-[485px]:min-h-[450px] min-[485px]:max-w-[400px] min-[485px]:rounded xl:scale-125">
      <img
        src={RegisterFailImage}
        className="mx-auto h-[135px] w-[202px]"
        alt=""
      />
      <h1 className="mt-4 text-center font-sans text-2xl font-semibold dark:text-white">
        Unable to register
      </h1>
      <div className="pt-1 text-center font-sans text-sm dark:text-gray-400">
        {getMessage()}
        &nbsp;
        <a
          href="https://discord.gg/3bhXYtd8bt"
          className="underline dark:text-blue-600"
        >
          Contact us
        </a>
        &nbsp;to learn more.
      </div>

      <button
        onClick={onClose}
        className="mt-5 w-full rounded bg-purple-800 py-2 font-sans text-sm font-semibold text-white"
      >
        Close
      </button>
    </div>
  );
}
