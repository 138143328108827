import React from "react";
import Success from "./Success";
import Failure from "./Failure";

export default function Callback() {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");

  return (
    <div className="min-h-screen items-center justify-center min-[485px]:flex">
      {status === "201" ? <Success /> : <Failure status={status} />}
    </div>
  );
}
