import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";
import GettingStartedHome from "../home/GettingStartedHome";

function GettingStarted() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />
      <div className="xl:mt-40">
        <GettingStartedHome />
      </div>
      <div className="flex-grow px-5 xl:mx-96 xl:px-32">
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Apply for beta
        </h2>
        <p
          className="mb-5 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Join our{" "}
          <a
            className="text-blue-600 underline"
            href="https://discord.gg/3bhXYtd8bt"
          >
            discord
          </a>{" "}
          and apply for the closed beta.
        </p>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          We're looking for passionate and dedicated players who are willing to
          help us test and improve our plugins before they are released to the
          public. To apply for our closed beta, we'd love to hear about your
          past experience with custom plugins and clients you've used. Sharing a
          bit on how knowledgeable you are of the Gielinor world will also be
          useful if you intend in testing a wide range of plugins. If you have
          any past beta testing experiences, we'd love to hear about those too.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Register an account
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Once you've been accepted to be a closed beta tester, you will receive
          a registration link and a registration key. You can headover to the{" "}
          <a className="text-blue-600 underline" href="/register">
            register page
          </a>{" "}
          and register your account to use Kuri plugins.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          Download Kuri Launcher
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          After creating you Kuri account you can start using Kuri Plugins,
          simply headover to{" "}
          <a
            className="text-blue-600 underline"
            href="https://discord.gg/3bhXYtd8bt"
          >
            discord
          </a>{" "}
          and on the{" "}
          <a
            className="text-blue-600 underline"
            href="https://discord.com/channels/1094266705909403749/1133105078635610202"
          >
            #launcher-updates
          </a>{" "}
          channel you'll find the downloadable launcher.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
        >
          Enjoy all the beta plugins!
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          Now that you have your launcher, start it and login using your Kuri
          Plugins credentials. Hope you enjoy the wide range of Kuri Plugins,
          and don't forget to give us feedback so we can keep improving!
        </p>
      </div>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default GettingStarted;
