import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavigationItem({ to, children }) {
  const location = useLocation();
  const active = location.pathname === to;

  return (
    <li>
      <Link
        to={to}
        className={
          "mx-3 text-sm uppercase " +
          (active
            ? "active bg-gradient-to-r from-[#9B51E0] to-[#3081ED] bg-clip-text font-bold text-transparent underline decoration-[#9B51E0] underline-offset-4"
            : "font-medium text-footerLinks underline-offset-4 transition duration-150 ease-in-out hover:text-white hover:underline")
        }
      >
        {children}
      </Link>
    </li>
  );
}

export default NavigationItem;
