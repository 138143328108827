import React from "react";
import { Link } from "react-router-dom";

function PluginCard(props) {
  return (
    <div className="h-full w-[280px] rounded-lg bg-[#1B1924]">
      <div className="mb-5 flex w-full items-end justify-center">
        <img
          src={props.img}
          alt=""
          className="h-[150px] w-full rounded-[10px] rounded-b-none"
        />
      </div>
      <div className="p-3">
        <div id="divToGrow" className="flex flex-grow">
          <p className="flex-wrap justify-start text-left text-base font-semibold text-white">
            {props.name}
          </p>
          <div className="flex-grow" />
          <p className="flex-wrap justify-end text-left text-base font-medium text-white">
            {props.version}
          </p>
        </div>
        <div className="mt-4 flex border-t border-secondary-200 pt-5">
          <p className="text-left text-base text-white">{props.category}</p>
          <div className="flex-grow" />
          <div className="justify-end">
            <Link
              to={`/plugin-details/${props.id}`}
              className="relative flex w-[73px] items-center justify-center rounded-lg border border-secondary-100 text-secondary-100 transition duration-150 ease-in-out hover:text-white"
            >
              <p className="flex-shrink-0 flex-grow-0 text-left text-base font-medium">
                View
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PluginCard;
