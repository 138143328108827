import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavigationItem from "./NavigationItem";
import KuriLogo from "../../assets/kuri-logo.png";
import MobileMenu from "./MobileMenu";

function Header() {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed z-30 w-full transition duration-300 ease-in-out md:bg-opacity-90 ${
        !top && "bg-background-100 shadow-lg backdrop-blur-sm"
      }`}
    >
      <div className="px-5 sm:px-6 md:mx-20 xl:mx-40">
        <div className="flex h-16 items-center justify-start md:h-20">
          {/* Site branding */}
          <div className="mr-4 flex-shrink-0">
            {/* Logo */}
            <Link to="/" className="block">
              <img src={KuriLogo} className="h-8 w-8" alt="" />
            </Link>
          </div>

          {/* Desktop Site navigation */}
          <nav className="hidden md:flex md:grow">
            <ul className="flex grow flex-wrap items-center justify-center">
              <NavigationItem to="/">Home</NavigationItem>
              <NavigationItem to="/plugins">Plugins</NavigationItem>
              <NavigationItem to="/getting-started">
                Getting Started
              </NavigationItem>
              <NavigationItem to="/about">About</NavigationItem>
            </ul>
            <ul className="flex flex-wrap items-center justify-end">
              <li>
                <Link
                  to="/register"
                  className="btn-sm btn-gradient ml-3 text-white transition duration-150 ease-in-out hover:bg-white"
                >
                  <span>Register</span>
                  <svg
                    className="-mr-1 ml-2 h-3 w-3 flex-shrink-0 fill-current text-white"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                      fillRule="nonzero"
                    />
                  </svg>
                </Link>
              </li>
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>
    </header>
  );
}

export default Header;
