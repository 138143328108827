import React from "react";
import PluginCard from "../home/PluginCard";
import Header from "../home/Header";
import Footer from "../home/Footer";
import { KuriPlugins } from "../../constants/data";

function Plugins() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      <div className="pt-10 md:pt-40">
        {/*  Page content */}
        <section className="relative">
          <div className="px-5 py-14 sm:px-6 md:mx-20 xl:mx-40">
            <div className="pb-12 md:pb-20">
              {/* Header */}
              <div className="mb-10 flex 2xl:mx-auto 2xl:w-5/6">
                <div className="flex-wrap justify-start text-left text-2xl font-medium text-white md:text-5xl">
                  Plugins
                </div>
              </div>
              {/* Plugin list */}
              <div
                className="grid gap-7 auto-fit-[280px] 2xl:mx-auto 2xl:w-5/6"
                data-aos="zoom-y-out"
              >
                {KuriPlugins.map((plugin, index) => (
                  <div className="mx-auto snap-start">
                    <PluginCard
                      key={index}
                      id={plugin.id}
                      img={plugin.img}
                      name={plugin.name}
                      version={plugin.version}
                      category={plugin.category}
                      description={plugin.description}
                      readme={plugin.readme}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Plugins;
