import WoodcuttingIcon from "../assets/woodcutting-icon.png";

export const KuriPlugins = [
  {
    id: "kuri-click-tempoross",
    status: "",
    img: "/assets/kuri-click-tempoross/thumbnail.png",
    name: "Kuri Click Tempoross",
    version: "v1.1.0",
    category: "Minigame",
    description: "Kuri click helper for Tempoross minigame",
    readme: "/assets/kuri-click-tempoross/README.md",
    icon: "",
  },
  {
    id: "hunllef-helper",
    status: "",
    img: "/assets/hunllef-helper/thumbnail.png",
    name: "Hunllef Helper",
    version: "v1.0.5",
    category: "Combat",
    description: "Helper for the gauntlet minigame",
    readme: "/assets/hunllef-helper/README.md",
    icon: "",
  },
  {
    id: "kuri-background-clicker",
    status: "POPULAR",
    img: "/assets/kuri-background-clicker/thumbnail.png",
    name: "Kuri Background Clicker",
    version: "v1.0.5",
    category: "Miscellaneous",
    description: "Fowards user input to Runelite",
    readme: "/assets/kuri-background-clicker/README.md",
    icon: "",
  },
  {
    id: "kuri-click-gotr",
    status: "POPULAR",
    img: "/assets/kuri-click-gotr/thumbnail.png",
    name: "Kuri Click GOTR",
    version: "v1.0.5",
    category: "Minigame",
    description: "Kuri click helper for GOTR minigame",
    readme: "/assets/kuri-click-gotr/README.md",
    icon: "",
  },
  {
    id: "kuri-click-mta",
    status: "FEATURED",
    img: "/assets/kuri-click-mta/thumbnail.png",
    name: "Kuri Click MTA",
    version: "v1.0.0",
    category: "Minigame",
    description: "Kuri click helper for MTA minigame",
    readme: "/assets/kuri-click-mta/README.md",
    icon: "",
  },
  {
    id: "kuri-click-wintertodt",
    status: "POPULAR",
    img: "/assets/kuri-click-wintertodt/thumbnail.png",
    name: "Kuri Click Wintertodt",
    version: "v1.2.7",
    category: "Minigame",
    description: "Kuri click helper for Wintertodt",
    readme: "/assets/kuri-click-wintertodt/README.md",
    icon: "",
  },
  {
    id: "kuri-click-chop",
    status: "HOT",
    img: "/assets/kuri-click-chop/thumbnail.png",
    gif: "/assets/kuri-click-chop/kuri-click-chop.gif",
    name: "Kuri Click Chop",
    version: "v1.2.2",
    category: "Skilling",
    description: "Kuri click helper for woodcutting",
    readme: "/assets/kuri-click-chop/README.md",
    icon: WoodcuttingIcon,
  },
  {
    id: "kuri-click-thieving",
    status: "FEATURED",
    img: "/assets/kuri-click-thieving/thumbnail.png",
    name: "Kuri Click Thieving",
    version: "v1.1.13",
    category: "Skilling",
    description: "Kuri click helper for Thieving",
    readme: "/assets/kuri-click-thieving/README.md",
    icon: "",
  },
  {
    id: "kuri-click-tithe-farm",
    status: "POPULAR",
    img: "/assets/kuri-click-tithe-farm/thumbnail.png",
    name: "Kuri Click Tithe Farm",
    version: "v1.0.6",
    category: "Skilling",
    description: "Click anywhere to do Tithe Farm.",
    readme: "/assets/kuri-click-tithe-farm/README.md",
    icon: "",
  },
  {
    id: "wilderness-player-alarm-extended",
    status: "",
    img: "/assets/wilderness-player-alarm-extended/thumbnail.png",
    name: "Wilderness Player Alarm Extended",
    version: "v1.1.1",
    category: "Miscellaneous",
    description: "Does things when it finds players within wilderness.",
    readme: "/assets/wilderness-player-alarm-extended/README.md",
    icon: "",
  },
  {
    id: "zulrah-helper",
    status: "",
    img: "/assets/zulrah-helper/thumbnail.png",
    name: "Zulrah Helper",
    version: "v1.0.3",
    category: "Combat",
    description: "Helps with Zulrah Fight.",
    readme: "/assets/zulrah-helper/README.md",
    icon: "",
  },
  {
    id: "kuri-gear-swapper",
    status: "FEATURED",
    img: "/assets/kuri-gear-swapper/thumbnail.png",
    name: "Kuri Gear Swapper",
    version: "v1.5.2",
    category: "Combat",
    description: "Helper for PvP/PvM",
    readme: "/assets/kuri-gear-swapper/README.md",
    icon: "",
  },
  {
    id: "kuri-click-rogues",
    status: "FEATURED",
    img: "/assets/kuri-click-rogues/thumbnail.png",
    name: "Kuri Click Rogues",
    version: "v1.0.9",
    category: "Minigame",
    description: "Click anywhere to go through Rogues' Den",
    readme: "/assets/kuri-click-rogues/README.md",
    icon: "",
  },
  {
    id: "kuri-click-hunter",
    status: "FEATURED",
    img: "/assets/kuri-click-hunter/thumbnail.png",
    name: "Kuri Click Hunter",
    version: "v1.3.1",
    category: "Skilling",
    description: "Kuri click helper for Hunter",
    readme: "/assets/kuri-click-hunter/README.md",
    icon: "",
  },
  {
    id: "kuri-click-fishing",
    status: "",
    img: "/assets/kuri-click-fishing/thumbnail.png",
    name: "Kuri Click Fishing",
    version: "v1.0.6",
    category: "Skilling",
    description: "Kuri click helper for Fishing",
    readme: "/assets/kuri-click-fishing/README.md",
    icon: "",
  },
  {
    id: "kuri-menu-swapper",
    status: "",
    img: "/assets/kuri-menu-swapper/thumbnail.png",
    name: "Kuri Menu Swapper",
    version: "v1.1.1",
    category: "Miscellaneous",
    description: "Swaps menu options with custom ones",
    readme: "/assets/kuri-menu-swapper/README.md",
    icon: "",
  },
  {
    id: "kuri-click-shop",
    status: "",
    img: "/assets/kuri-click-shop/thumbnail.png",
    name: "Kuri Click Shop",
    version: "v1.1.0",
    category: "Money Making",
    description: "Kuri click helper for buying items from the shop",
    readme: "/assets/kuri-click-shop/README.md",
    icon: "",
  },
  {
    id: "kuri-click-enchant",
    status: "",
    img: "/assets/kuri-click-enchant/thumbnail.png",
    name: "Kuri Click Enchant",
    version: "v1.1.3",
    category: "Skilling",
    description: "Kuri click helper for the enchanting spell",
    readme: "/assets/kuri-click-enchant/README.md",
    icon: "",
  },
  {
    id: "kuri-click-dye",
    status: "",
    img: "/assets/kuri-click-dye/thumbnail.png",
    name: "Kuri Click Dye",
    version: "v1.0.5",
    category: "Money Making",
    description: "Kuri click helper for creating dyes",
    readme: "/assets/kuri-click-dye/README.md",
    icon: "",
  },
  {
    id: "kuri-click-craft",
    status: "",
    img: "/assets/kuri-click-craft/thumbnail.png",
    name: "Kuri Click Craft",
    version: "v1.2.0",
    category: "Skilling",
    description: "Kuri click helper for Crafting",
    readme: "/assets/kuri-click-craft/README.md",
    icon: "",
  },
  {
    id: "kuri-click-combine",
    status: "",
    img: "/assets/kuri-click-combine/thumbnail.png",
    name: "Kuri Click Combine",
    version: "v1.1.2",
    category: "Miscellaneous",
    description: "Kuri click helper for combining items",
    readme: "/assets/kuri-click-combine/README.md",
    icon: "",
  },
  {
    id: "kuri-click-agility",
    status: "FEATURED",
    img: "/assets/kuri-click-agility/thumbnail.png",
    name: "Kuri Click Agility",
    version: "v1.0.16",
    category: "Skilling",
    description: "Kuri click helper for agility",
    readme: "/assets/kuri-click-agility/README.md",
    icon: "",
  },
  {
    id: "kuri-click-blast-furnace",
    status: "FEATURED",
    img: "/assets/kuri-click-blast-furnace/thumbnail.png",
    name: "Kuri Click Blast Furnace",
    version: "v1.0.7",
    category: "Skilling",
    description: "Kuri click helper for blast furnace",
    readme: "/assets/kuri-click-blast-furnace/README.md",
    icon: "",
  },
  {
    id: "kuri-click-defenders",
    status: "FEATURED",
    img: "/assets/kuri-click-defenders/thumbnail.png",
    name: "Kuri Click Defenders",
    version: "v1.0.4",
    category: "Minigame",
    description: "Kuri click helper for defenders",
    readme: "/assets/kuri-click-defenders/README.md",
    icon: "",
  },
  {
    id: "kuri-click-nmz",
    status: "FEATURED",
    img: "/assets/kuri-click-nmz/thumbnail.png",
    name: "Kuri Click NMZ",
    version: "v1.0.4",
    category: "Minigame",
    description: "Kuri click helper for Nightmare Zone",
    readme: "/assets/kuri-click-nmz/README.md",
    icon: "",
  },
  {
    id: "kuri-click-alch",
    status: "FEATURED",
    img: "/assets/kuri-click-alch/thumbnail.png",
    name: "Kuri Click Alch",
    version: "v1.0.0",
    category: "Skilling",
    description: "Kuri click helper to alch/stun/teleport",
    readme: "/assets/kuri-click-alch/README.md",
    icon: "",
  },
  {
    id: "gwd-helper",
    status: "",
    img: "/assets/gwd-helper/thumbnail.png",
    name: "GWD Helper",
    version: "v1.0.2",
    category: "Combat",
    description: "God Wars Dungeon helper",
    readme: "/assets/gwd-helper/README.md",
    icon: "",
  },
  {
    id: "inferno-helper",
    status: "",
    img: "/assets/inferno-helper/thumbnail.png",
    name: "Inferno Helper",
    version: "v1.0.4",
    category: "Combat",
    description: "Inferno helper",
    readme: "/assets/inferno-helper/README.md",
    icon: "",
  },
  {
    id: "toa-extended",
    status: "",
    img: "/assets/toa-extended/thumbnail.png",
    name: "TOA Extended",
    version: "v1.0.3",
    category: "Combat",
    description: "Helper for Tombs of Amascut",
    readme: "/assets/toa-extended/README.md",
    icon: "",
  },
  {
    id: "auto-pray-flick",
    status: "",
    img: "/assets/auto-pray-flick/thumbnail.png",
    name: "Prayer Flicker",
    version: "v1.0.1",
    category: "Combat",
    description: "Prayer flicker",
    readme: "/assets/auto-pray-flick/README.md",
    icon: "",
  },
];
