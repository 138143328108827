import React from "react";
import RegisterSuccessImage from "../../assets/register-success.png";

const onClose = () => {
  window.opener = null;
  window.open("about:blank", "_self");
  window.close();
};

export default function Success() {
  return (
    <div className="box-background mx-auto w-full shadow-lg max-[485px]:absolute max-[485px]:h-full max-[485px]:min-h-[450px] min-[485px]:max-w-[400px] min-[485px]:rounded xl:scale-125">
      <div className="relative">
        <img src={RegisterSuccessImage} className="flex " alt="" />
      </div>
      <div className="px-10 pb-10 pt-5">
        <h1 className="text-center font-sans text-2xl font-semibold dark:text-white">
          Success!
        </h1>
        <div className="pt-1 text-center font-sans text-sm dark:text-gray-400">
          You've successfully registered an account!
        </div>
        <div className="pb-1 text-center font-sans text-sm dark:text-gray-400">
          You can now close this tab.
        </div>
        <button
          onClick={onClose}
          className="mt-5 w-full rounded bg-purple-800 py-2 font-sans text-sm font-semibold text-white"
        >
          Close
        </button>
      </div>
    </div>
  );
}
