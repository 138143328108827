import Register from "./components/register/Register";
import Home from "./components/home/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Privacy from "./components/policies/Privacy";
import Terms from "./components/policies/Terms";
import Plugins from "./components/plugins/Plugins";
import PluginDetails from "./components/plugin-details/PluginDetails";
import GettingStarted from "./components/getting-started/GettingStarted";
import About from "./components/about/About";
import Callback from "./components/register/Callback";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/callback" element={<Callback />} />
        <Route path="/plugins" element={<Plugins />} />
        <Route path="/plugin-details/:id" element={<PluginDetails />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
