import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";

export default function Terms() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow px-5 pt-10 xl:mx-96 xl:px-32 xl:pt-40">
        <h1 className="mb-10 pt-16 text-center text-4xl font-bold text-white">
          Terms and Conditions
        </h1>

        <p className="mb-10 text-left text-base leading-relaxed text-white md:text-lg">
          <b>Last Updated:</b> 15th October 2023
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Acceptance of Terms
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          By accessing or using the Kuri Plugins website at{" "}
          <a
            className="text-blue-600 underline"
            href="https://kuriplugins.com/"
          >
            https://kuriplugins.com/
          </a>{" "}
          and any of its services, products, or features (collectively referred
          to as the "Website"), you agree to be bound by these Terms and
          Conditions.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Use of the Website
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          You agree to use the Website only for lawful purposes and in a manner
          consistent with all applicable laws and regulations. You are solely
          responsible for your use of the Website and any consequences that may
          result from your use of it.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Intellectual Property
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          All content and materials on the Website, including but not limited to
          text, graphics, logos, images, and software, are the property of Kuri
          Plugins and are protected by copyright and other intellectual property
          laws. You may not reproduce, distribute, or create derivative works
          based on this content without our explicit permission.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Disclaimer of Warranties
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          The Website is provided on an "as-is" and "as-available" basis. Kuri
          Plugins makes no warranties, express or implied, regarding the
          accuracy, reliability, or availability of the Website. We disclaim all
          warranties, including but not limited to warranties of merchantability
          and fitness for a particular purpose.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Limitation of Liability
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          Kuri Plugins and its affiliates shall not be liable for any direct,
          indirect, incidental, special, or consequential damages resulting from
          your use of the Website. This includes, but is not limited to, damages
          for loss of profits, data, or other intangible losses.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Changes to Terms and Conditions
        </h2>
        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          We may update these Terms and Conditions from time to time. Any
          changes will be posted on this page with a revised "Last Updated"
          date. It is your responsibility to review the Terms and Conditions
          regularly to stay informed about any changes.
        </p>

        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          Contact Us
        </h2>
        <p className="mb-5 text-left text-base leading-relaxed text-white md:text-lg">
          If you have any questions, concerns, or feedback regarding these Terms
          and Conditions, please contact us at:
        </p>

        <p className="mb-20 text-left text-base leading-relaxed text-white md:text-lg">
          Email:{" "}
          <a
            className="text-blue-600 underline"
            href="mailto:kuri.plugins@gmail.com"
          >
            kuri.plugins@gmail.com
          </a>
        </p>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}
